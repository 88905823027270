import React from "react"
import { Row, Col, Container, Card } from "react-bootstrap"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Blurb from "../components/blurb"
import Header from "../components/header"
import nissanLogo from "../images/nissan.svg.png"
import kiaLogo from "../images/kia-logo.svg.png"
import mitsubishiLogo from "../images/mitsubishi.svg.png"
import hyundaiLogo from "../images/hyundai-logo.svg"
import hondaLogo from "../images/honda.svg"
import bcLogo from "../images/bc.png"
import abLogo from "../images/ab.png"
import skLogo from "../images/sk.png"
import ytLogo from "../images/yukon.jpeg"

export const query = graphql`
  {
    nissanLogo: file(relativePath: { eq: "nissan.png" }) {
      childImageSharp {
        fluid(maxWidth: 40) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    memberLogo: file(relativePath: { eq: "memberships.png" }) {
      childImageSharp {
        fluid(maxWidth: 2140) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const phoneNum = process.env.GATSBY_PHONE_NUMBER
  const email = process.env.GATSBY_EMAIL_ADDRESS
  const logo = data.memberLogo.childImageSharp.fluid

  // const text = <h2>Guiding Principles:</h2>

  return (
    <Layout
      className="index-container mt-1"
      pageInfo={{ pageName: "dealerships" }}
    >
      <Seo
        title="Dealerships"
        keywords={[`dealerships`, `participating`, `secure ride, protection`]}
      />
      <Header logo={logo.src} />
      <h1
        style={{
          color: "white",
          textAlign: "center",
          paddingTop: "15px",
        }}
      >
        Guiding Principles:
      </h1>
      {/* <Blurb text={text} /> */}
      <Container fluid>
        <Row
          className="my-2 py-2"
          display="flex"
          style={{ justifyContent: "center" }}
        >
          <Col className="d-flex justify-content-end" sm={{ span: 8 }}>
            <Row>
              <Col>
                <Card className="card-dealers card-bc" border="dark" bg="light">
                  <Card.Body>
                    {/* <Card.Img
                      src={bcLogo}
                      variant="top"
                      alt="BC Logo"
                      className="page-logo-img pb-2"
                    /> */}
                    {/* <Card.Title>British Columbia Dealers:</Card.Title> */}
                    <Card.Text style={{ textAlign: "left" }}>
                      In connection with your transaction, Secure Ride may
                      acquire information about you as described in this notice,
                      which we handle as stated in this notice. <br />
                      <br /> We may collect personally identifiable information
                      such as name, postal address, telephone number, e-mail
                      address, social security number, date of birth, etc This
                      personal information is collected and used by Secure Ride
                      staff for the purpose of facilitating a relationship or
                      business transaction. <br />
                      <br />
                      Our website resides behind a firewall and uses SSL (Secure
                      Sockets Layer, the industry-standard security protocol
                      used to communicate with browsers) to transmit personal
                      information. Data is strongly encrypted during
                      transmission to ensure that personal and payment
                      information is secure. Industry-standard data encryption
                      techniques are used to protect personal information on our
                      servers. SSL-capable browsers typically have a symbol on
                      the browser window to indicate when they are in a secure
                      mode. In addition, the URL will begin with "https:" for
                      all browsers. <br />
                      <br />
                      Secure Ride does not sell, rent or disclose e-mail
                      addresses to other organizations. <br />
                      <br />
                      Please read Secure Rides's privacy policy before using
                      this website. By using Secure Ride's website, you
                      acknowledge and agree you have read and agree to the
                      following privacy terms. <br />
                      <br />
                      In connection with your transaction, whether online or at
                      our dealership, Secure Ride may acquire information about
                      you as described in this policy, which Secure Ride handles
                      as stated in this policy. Secure Ride may collect
                      personally identifiable information such as name, postal
                      address, telephone number, email address, social security
                      number, date of birth, etc. While Secure Ride makes
                      reasonable efforts to secure all data submitted via this
                      website, Secure Ride cannot guarantee security of personal
                      information, and all information submitted via this
                      website is at your own risk. By visiting this website
                      and/or agreeing to share your location with Secure Ride,
                      Secure Ride may acquire non-personally identifiable
                      information about you, such as your geographic location,
                      internet service provider, internet browser, IP address,
                      search engine or referral source you used to access this
                      website, and browsing preferences. By using this website,
                      you acknowledge and agree Secure Ride may use this
                      anonymized information to improve the experience of our
                      customers and visitors to this website. Secure Ride uses
                      cookies on this website to help collect some identifiable
                      personal data. The cookies also enable Secure Ride to tie
                      the URL of the device you use to provide certain personal
                      information on Secure Rides's website to other personal
                      information you may provide to Secure Ride at the
                      dealership. This personal information is collected and
                      used by Secure Ride for the purpose of facilitating a
                      relationship or business transaction and customizing
                      Secure Ride's interaction with you. By entering and using
                      our website, and by voluntarily providing Secure Ride with
                      your personal information, you consent to Secure Ride
                      tying your personal information to the URL of the device
                      you use to enter Secure Ride's website. Disabling the
                      cookies on your web browser or withholding your personal
                      information at the dealership will prevent Secure Ride
                      from tying your URL to any personal information you
                      provided to Secure Ride at the dealership. <br />
                      <br />
                      In addition, Secure Ride or our business partners use
                      Google Analytics to help collect and process some of the
                      information Secure Ride collects about you. To learn more
                      about how Google uses data when you use this site, please
                      review Google’s privacy policy here:
                      www.google.com/policies/privacy/partners/. Secure Ride's
                      website resides behind a firewall and uses SSL (Secure
                      Sockets Layer, the industry-standard security protocol
                      used to communicate with browsers) to transmit personal
                      information. Data is strongly encrypted during
                      transmission to ensure that personal and payment
                      information is secure. Industry-standard data encryption
                      techniques are used to protect personal information on our
                      servers. SSL-capable browsers typically have a symbol on
                      the browser window to indicate when they are in a secure
                      mode. In addition, the URL will begin with "https:" for
                      all browsers. Secure Ride only shares your information
                      with our affiliates or business partners, and such
                      disclosure is made only in connection with our regular
                      business practices. Secure Ride does not sell, rent, or
                      disclose email addresses to any other organizations.{" "}
                      <br />
                      <br />
                      If you have any questions about this policy or Secure
                      Ride's privacy practices, please contact Secure Ride.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
