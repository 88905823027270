import React from "react"
import { Row, Col } from "react-bootstrap"

const Blurb = ({ text }) => {
  return (
    <Row className="mt-4 mb-2 ">
      <Col className="d-flex text-center justify-content-center align-items-center pl-5 pr-5">
        <h3 className="text-light blurb"> {text}</h3>
      </Col>
    </Row>
  )
}

export default Blurb
